import React from "react"

export function Headline({ dark, children, eyebrow }) {
  return (
    <div>
      {eyebrow && (
        <div className="mb-3">
          <span className="text-primary text-sm font-medium leading-none">
            {eyebrow}
          </span>
        </div>
      )}
      <h2
        className={`break-words font-medium text-2xl xl:text-3xl leading-snug xl:leading-relaxed ${
          dark ? "text-white" : "text-gray-800 "
        }`}
      >
        {children}
      </h2>
    </div>
  )
}

export default function Section({ dark, children }) {
  return (
    <div
      className={`py-16 lg:py-16 xl:py-24 ${
        dark ? "bg-gray-900 text-white" : ""
      }`}
    >
      {children}
    </div>
  )
}

Section.Headline = Headline
