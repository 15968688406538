import React from "react"
import parse from "html-react-parser"
import Section from "components/Section"
import TrophyIcon from "images/icons/icon-trophy.png"
import DatabaseIcon from "images/icons/icon-database.png"
import ProfessionalIcon from "images/icons/icon-professional.png"
import KoreanSymbolIcon from "images/icons/icon-korean-symbol.png"
import WhyUs from "images/hpc_20220103_1200.jpg"

export default function SectionWhyUs() {
  return (
    <Section>
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="mb-8 lg:mb-16 max-w-xl mx-auto text-center">
          <Section.Headline eyebrow={"Why choose us"}>
            다년간의 컨설팅을 수행한 경험을 바탕으로 조직에 최적화된 솔루션을
            제공합니다.
          </Section.Headline>
        </div>
        <div className="flex flex-row flex-wrap -mx-4 -my-1">
          {[
            {
              key: "awards",
              icon: TrophyIcon,
              title: "다양한 업종별 컨설팅 수행 경험 및 컨설팅 방법론 보유",
              titleHtml: `다양한 업종별 컨설팅 <br/>수행 경험 및 
              컨설팅 방법론 보유`,
            },
            {
              key: "cases",
              icon: DatabaseIcon,
              title: "업종별·분야별 컨설팅 성공사례 보유",
              titleHtml: ` 업종별·분야별 컨설팅 <br />
              성공사례 보유`,
            },
            {
              key: "professional",
              icon: ProfessionalIcon,
              title: "HRM, HRD, 제조혁신 등 최고 수준의 전문가 보유",
              titleHtml: `HRM, HRD, 제조혁신 등 <br />
                  최고 수준의 전문가 보유`,
            },
            {
              key: "collaboration",
              icon: KoreanSymbolIcon,
              title: "고용노동부 정책 연구 참여",
              titleHtml: `고용노동부 <br />
                    정책 연구 참여`,
            },
          ].map(item => {
            return (
              <div
                className="w-full sm:w-1/2 xl:w-1/4 px-4 mb-16"
                key={item.key}
              >
                <div className="bg-white border text-center pt-3 pb-6 px-4 rounded-xl shadow-sm">
                  <div className="-mt-10 mb-3">
                    <div className="bg-primary w-16 h-16 rounded-full items-center justify-center inline-flex">
                      <img
                        src={item.icon}
                        alt={item.title}
                        className="h-10 mx-auto"
                      />
                    </div>
                  </div>
                  {/* <span className="text-primary mb-3 block">네번째</span> */}
                  <p className="break-words text-xl font-medium">
                    {parse(item.titleHtml)}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Section>
  )
}
