import React from "react"
import PageHeader from "components/PageHeader"
import Seo from "components/Seo"
import SectionPrograms from "components/SectionPrograms"
import SectionWhyUs from "components/SectionWhyUs"
import SectionContactCta from "components/SectionContactCta"

const CASES = [
  {
    title: "조직역량개발",
    description:
      "기업의 전략 및 핵심경쟁력과 연계하여 필요한 조직역량을 정의하고 이를 위한 조직의 Intervention 방안을 개발합니다.",
  },

  {
    title: "인재육성전략 수립",
    description:
      "기업에 필요한 인재군(Talent Portfolio)을 사전적으로 정의하고, 인재군별 선별, 육성, 평가, 보상 등 효율적 관리를 위한 전략을 개발합니다.",
  },

  {
    title: "Succession Planning",
    description:
      "CEO 및 핵심임원 후보자 선발기준을 정의하고, 후보자가 안정적으로 승계를 할 수 있도록 육성 및 지원체계를 개발합니다.",
  },

  {
    title: "핵심인재 육성체계 구축",
    description:
      "기업에 필요한 핵심인재를 육성하고 유지하기 위해, 핵심인재 선발기준을 정의하고 경력개발 및 교육과 연계한 육성방안을 개발합니다.",
  },

  {
    title: "리더십 Pipeline 구축",
    description:
      "리더에게 필요한 역량·자질을 정의하고, 리더로서의 역할을 안정적으로 수행할 수 있도록 리더육성체계를 개발합니다.",
  },

  {
    title: "경력개발체계 구축",
    description:
      "매력적인 경력목표(Career Vision)을 개발하고 경력목표를 달성할 수 있도록 경력개발경로 및 체계를 구축합니다.",
  },

  {
    title: "교육훈련과정 설계",
    description:
      "업무수행에 필요한 역량·지식·자질을 정의하고 이를 효과적으로 육성할 수 있는 교육훈련과정을 개발합니다.",
  },

  {
    title: "Assessment체계 구축",
    description:
      "직원 역량수준에 대한 정확한 평가를 위해 Assessment Tool(평가기준/평가방법) 및 평가자 운영방안을 개발합니다.",
  },
]

export default function HrdProgramPage() {
  return (
    <div>
      <Seo
        title={"인적자원개발 (Human Resource Development)"}
        description="HRD는 급속하게 변화하는 기업 환경 속에서 조직의 빠른 기술변화 대응과 구성원의 직무역량을 강화할 수 있도록 설계되어야 합니다"
      />
      <PageHeader
        title={"인적자원개발 (HRD)"}
        description="HRD는 급속하게 변화하는 기업 환경 속에서 조직의 빠른 기술변화 대응과 구성원의 직무역량을 강화할 수 있도록 설계되어야 합니다"
      />

      <SectionPrograms source={CASES} />

      <SectionWhyUs />

      <SectionContactCta />
    </div>
  )
}
