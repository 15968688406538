import React from "react"

export default function SectionPrograms({ source }) {
  return (
    <div className="w-full lg:container mx-auto px-4 lg:px-8">
      <div className="p-4 lg:p-8 bg-gray-50">
        <div className="flex flex-row flex-wrap -mx-4 -my-4">
          {source.map((item, index) => {
            return (
              <div
                className="px-4 w-full lg:w-1/2 xl:w-1/3 my-4"
                key={`service-${index}`}
              >
                <div className="h-full rounded-md">
                  <div className="flex flex-row">
                    <div className="mr-3">
                      <span className="w-2 h-2 inline-block bg-primary"></span>
                    </div>
                    <div>
                      <h4 className="text-gray-900 text-lg mb-1">
                        {item.title}
                      </h4>
                      <p className="text-gray-500 break-words">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
