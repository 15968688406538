import React from "react"

export default function PageHeader({ title, description }) {
  return (
    <div className="py-8 lg:py-16">
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <h2 className="text-2xl lg:text-3xl xl:text-4xl font-medium break-words lg:leading-snug xl:leading-snug">
          {title}
        </h2>
        {description && (
          <p className="mt-3 lg:mt-3 lg:max-w-xl text-gray-500 break-word lg:text-lg break-words">
            {description}
          </p>
        )}
      </div>
    </div>
  )
}
