import React from "react"
import { Link } from "gatsby"
import { ParallaxBanner } from "react-scroll-parallax"
import { useResizeDetector } from "react-resize-detector"
import Section from "components/Section"
import BgContact from "images/bg-contact.jpg"

export default function SectionContactCta() {
  const { width, ref } = useResizeDetector()
  const isXs = width <= 640

  return (
    <ParallaxBanner
      layers={[
        {
          image: BgContact,
          amount: 0.4,
        },
      ]}
      style={{
        height: isXs ? "240px" : "380px",
      }}
      className="flex items-center justify-center"
    >
      <div className="w-full lg:container mx-auto px-4 lg:px-6 text-center relative z-30">
        <div>
          <Section.Headline dark>
            전문 컨설턴트와 상담받고 <br />
            실천 가능한 해결책을 찾아보세요.
          </Section.Headline>
          <div className="mt-8 flex justify-center">
            <Link
              to="/contact"
              className="bg-primary text-white font-medium rounded-full px-6 py-2 inline-block"
            >
              무료 상담신청
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 opacity-50 absolute inset-0 w-full h-full object-cover z-20"></div>
    </ParallaxBanner>
  )
}
